var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',_vm._b({attrs:{"id":"core-navigation-drawer","dark":_vm.barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',"expand-on-hover":_vm.expandOnHover,"right":_vm.$vuetify.rtl,"src":_vm.profile.sidebar,"mobile-breakpoint":"960","app":"","width":"260"},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({attrs:{"gradient":`to bottom, ${_vm.barColor}`}},'v-img',props,false))]}}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('v-divider',{staticClass:"mb-1"}),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"contain":""}},[_c('v-img',{attrs:{"src":_vm.profile.logo,"max-height":"30","contain":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"display-1",staticStyle:{"min-height":"30px","display":"flex","align-items":"center"},domProps:{"textContent":_vm._s(_vm.profile.title)}}),_c('div',{staticClass:"dashboard-version"},[_vm._v("v"+_vm._s(_vm.dashboardVersion))])],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-list',{attrs:{"expand":"","nav":""}},[_c('div'),_vm._l((_vm.computedItems),function(item,i){return [(
          _vm.user &&
          _vm.user.configurations &&
          _vm.user.configurations.menu_items &&
          _vm.user.configurations.menu_items.indexOf(item.id) > -1 &&
          _vm.hasPermission('menu', item.id)
        )?_c('base-item',{key:`item-${i}`,attrs:{"item":item,"data-id":item.id}}):_vm._e()]}),_c('div')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }